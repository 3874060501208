<template>

    <div v-show="!hidden" class="">

      <div class="trend-item p-1 league d-none" @click="loadData">

        <div class="d-flex">
          <div v-if="!leo" class="trend-text1 mr-1 pr-2">
            <span class="bold" v-text="country_name"></span> / <span v-text="competition.competition"></span>
          </div>
          <div v-if="leo" class="trend-text1 mr-1 pr-2">
            <!--<span  class="bold" v-text="sport_name"></span> / <span v-text="competition.competition"></span>-->
            <img v-bind:src="sportIcon(competition.sport_id)"
                 style="background-color: var(--black);height: 1.13em;border-radius: 50%; margin-right: 0.2em"/>
            <span v-text="competition.sport_name"></span> / <span class="bold" v-text="country_name"></span> / <span
              v-text="competition.competition"></span>
          </div>
        </div>

        <div class="trend-text">

      <span v-if="league.fixtures === undefined || league.fixtures.length === 0">
        <span class="numbers">{{ league.games }}</span>
        <span style="position: relative;top: 6px;">
          <arrow-right-icon style="height: 1em;"></arrow-right-icon>
        </span>
        <!--        <arrow-right-icon v-bind:size="20" style="padding-top: 10px;"></arrow-right-icon>-->
      </span>

      <span v-else>
        <span class="numbers">{{ league.fixtures.length }}</span>
        <span style="position: relative;top: 6px;">
          <arrow-right-icon style="height: 1em;"></arrow-right-icon>
        </span>

      </span>
        </div>

      </div>

      <fixture
          v-show="visible"
          v-for="(f,index) in fixtures"
          v-bind:key="getKey(index)"
          v-bind:live="live"
          v-bind:fixture="f">
      </fixture>

      <div class="timeline-wrapper" v-show="busy">

        <div class="timeline-item">

          <div class="animated-background facebook">

            <!-- lines -->

            <div class="background-masker" style="top: 0px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 17px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 34px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 51px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="bottom: 0px; left: 0px; width: 60%; height: 5px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 59%; width: 1%; "></div>

            <div class="background-masker" style="top: 0px; right: 0px; width: 40%; height: 7px; "></div>
            <div class="background-masker" style="bottom: 0px; right: 0px; width: 40%; height: 7px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 79%; width: 1%; "></div>

          </div>

        </div>

      </div>

    </div>

</template>

<script>

import fixture from './Fixture'
import axios from "@/services/api";

export default {
  name: "Competition",
  mounted: function () {

    this.leag = this.competition;
    this.visible = this.is_visible;
    this.fixtures = this.competition.fixtures === undefined ? [] : this.competition.fixtures;
    this.sport_name = this.competition.sport_name;
    this.sport_id = this.competition.sport_id;
    this.country_name = this.competition.country_name;

  },
  methods: {
    setBusy: function () {

      this.busy = true;

    },
    unBusy: function () {

      this.busy = false;

    },
    setFixture: function (fixtures) {
      this.fixtures = fixtures;
      this.visible = true;

    },
    toggleVisibility: function () {

      this.visible = !this.visible;

    },
    loadData: function () {

      this.toggleVisibility();

      if (!this.fixtures || this.fixtures.length === 0) {

        this.getFixture();

      }

    },

    sportIcon: function (sport_id) {
      return this.getSportIcon(sport_id);
    },

    getKey: function (index) {

      var prefix = "fixture-" + index;
      return Math.random().toString(10).replace('0.', 'match-id-' + prefix + '-');

    },
    getFixture: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_HIGHLIGHTS;

      if (this.highlights) {

        path = process.env.VUE_APP_URL_HIGHLIGHTS

      }

      if (this.live) {

        path = process.env.VUE_APP_URL_LIVE_ODDS

      }

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{competition_id}", 0);
      path = path.replace("{market_id}", vm.market_id);
      path = path.replace("{page}", 1);

      var payload = {
        highlights: 1,
        upcoming: 0,
        hours: vm.hour,
        country_code: "",
        date: vm.date,
        keyword: "",
        per_page: 100,
        competitions: this.leag.competition_id
      };

      if (vm.filterBy === 'competition_id') {

        payload.competitions = this.leag.competition_id
      }

      if (vm.filterBy === 'country_code') {

        payload.country_code = this.leag.country_code
      }

      if (this.date.length > 0) {

        payload.date = this.date;

      }

      if (parseInt(this.upcoming) > 0) {

        payload.upcoming = this.upcoming;

      }

      vm.setBusy()

      axios.post(path, JSON.stringify(payload))
          .then(res => {

            var games = res.data.message;
            vm.unBusy();
            vm.setFixture(games.data);

            vm.EventBus.$emit('event:leagues:show', vm.leag.competition_id);

          })
          .catch(err => {

            vm.unBusy();

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            } else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })
    },
  },
  computed: {
    league: {
      // getter
      get: function () {

        return this.leag

      },
      // setter
      set: function (newValue) {

        this.leag = newValue
      }
    },
    sport_id: {
      // getter
      get: function () {

        return this.sportID;

      },
      // setter
      set: function (newValue) {

        this.sportID = newValue
      }
    },
    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {
      return this.$store.state.hour;
    },
    competition_id: function () {
      return this.$store.state.competition_id;
    },
  },
  components: {
    fixture,
  },
  data: function () {
    return {
      sport_name: '',
      country_name: '',
      has_fixture: false,
      visible: false,
      busy: false,
      leag: {},
      fixtures: [],
      hidden: false,
      sportID: this.$store.state.sport_id
    }
  },
  props: {
    competition: {
      required: true,
    },
    is_visible: {
      required: false,
      default: false
    },
    is_busy: {
      required: false,
      default: false
    },
    filterBy: {
      default: 'competition_id'
    },
    date: {
      required: false,
      default: ""
    },
    upcoming: {
      required: false,
      default: false
    },
    today: {
      required: false,
      default: false
    },
    leo: {
      required: false,
      default: false
    },
    highlights: {
      required: false,
      default: false
    },
    live: {
      required: false,
      default: false
    },
    esport: {
      required: false,
      default: false
    },
  },
  watch: {
    market_id: function (newValue, oldValue) {

      console.log('market_id =>newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);
      this.getGames();
    },
    competition_id: function (newValue, oldValue) {

      console.log('competition_id =>newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);

      if (parseInt(newValue) === 0) {

        this.hidden = false;
        return
      }

      if (parseInt(newValue) === parseInt(this.leag.competition_id)) {

        this.hidden = false;
        this.loadData();
        return
      }

      this.hidden = true;

    }
  },
}

</script>